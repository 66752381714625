import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, Table } from "react-bootstrap";
import URL from "../../../API/Api";
import "./TaskForm.css";

const CustomTaskForm = ({
  FlowTask,
  Editform,
  editTaskFlowOrder,
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
  errors,
  selectedUsers = [],
  setSelectedUsers,
}) => {
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ProcessGetvalue, setProcessGetvalue] = useState();
  const [TaskNumberFlow, setTaskNumberFlow] = useState();
  const [TasksField, setTasksField] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [users, setUsers] = useState([]);

  const handleStatusChange = (e) => {
    setTaskStatus(e.target.value);
  };

  const fetchUsers = async () => {
    // Check if dept_id and dept_role_id are present in the form data
    if (!formData.dept_id || !formData.dept_role_id) return; // Skip if dept_id or dept_role_id is not selected

    try {
      // Send the GET request with dept_id and dept_role_id as query parameters
      const response = await URL.get("/api/task/usersData", {
        params: {
          dept_id: formData.dept_id,
          dept_role_id: formData.dept_role_id,
        },
      });

      // Handle successful response
      if (response.data.status) {
        const users = response.data.data;
        setUserDetails(users); // Update the state with the fetched user data
      } else {
        setUserDetails([]); // Clear the user list if no users are found
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching users:", error);
      setUserDetails([]); // Clear the user list in case of an error
    }
  };
  useEffect(() => {
    console.log("User Details:", userDetails);
  }, [userDetails]);

  useEffect(() => {
    fetchUsers();
  }, [formData.dept_id, formData.dept_role_id]);

  useEffect(() => {
    const fetchProcessesAndDepartments = async () => {
      if (!formData.org_id) return; // Skip if no organization is selected
      setLoading(true);

      try {
        const response = await URL.get(`api/org/${formData.org_id}`);
        if (response.data && response.data.status) {
          const orgData = response.data.data;

          const customProcess = orgData.processes.find(
            (process) =>
              process.process_name.toLowerCase() ===
              "custom process".toLowerCase()
          );

          if (customProcess) {
            const customProcessId = customProcess.process_id;
            console.log("Custom Process Id:", customProcessId);
            // Set the process_id in formData if needed
            setFormData((prevData) => ({
              ...prevData,
              process_id: customProcessId,
            }));
          } else {
            console.log("Process 'Custom Process' not found.");
          }

          // Format processes for the dropdown
          const formattedProcessOptions = orgData.processes.map((process) => ({
            value: process.process_id,
            label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
            key: `process-${process.process_id}`,
          }));
          setProcessOptions(formattedProcessOptions);

          // Format departments and their roles for the dropdown
          const formattedDepartments = orgData.departments.map((dept) => ({
            value: dept.department_id,
            label: dept.department_name,
            roles: dept.roles.map((role) => ({
              value: role.role_id,
              label: role.dep_role_name,
            })),
            key: dept.department_id,
          }));
          setDepartmentOptions(formattedDepartments);
        }
      } catch (error) {
        console.error("Error fetching processes or departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcessesAndDepartments();
  }, [formData.org_id]); // Trigger this when org_id changes

  const GetTaskFlowing = (d) => {
    const selectedProcess = FlowTask.find(
      (process) => process.id === parseInt(d)
    );
    if (selectedProcess) {
      setTasksField(selectedProcess.tasks);
      if (selectedProcess?.tasks?.length === 0) {
        setTaskNumberFlow(1);
      } else {
        const lastObject =
          selectedProcess?.tasks[selectedProcess?.tasks?.length - 1];
        const gettheFlowOrder = lastObject.task_flow_order;
        const AddCountFlowOrder = gettheFlowOrder + 1;

        setTaskNumberFlow(parseInt(AddCountFlowOrder));
      }
    } else {
      setTasksField([]);
      setTaskNumberFlow(1);
    }
  };
  console.log(roleOptions, "jkll");

  // Handle input changes
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    // Update the process ID and fetch related tasks when a process is selected
    if (name === "process_id") {
      setProcessGetvalue(value);
      GetTaskFlowing(parseInt(value, 10));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Fetch roles when a department is selected
    if (name === "dept_id" || name === "dept_role_id") {
      // Fetch users if dept_id and dept_role_id are both selected
      if (formData.dept_id && formData.dept_role_id) {
        fetchUsers();
      }
    }

    // Fetch roles when a department is selected
    if (name === "dept_id") {
      const selectedDepartmentId = parseInt(value, 10);
      const selectedDepartment = departmentOptions.find(
        (dept) => dept.value === selectedDepartmentId
      );

      if (selectedDepartment) {
        setRoleOptions(selectedDepartment.roles);

        // Automatically set dept_role_id to the first role or clear if no roles
        const newRoleId =
          selectedDepartment.roles.length > 0
            ? selectedDepartment.roles[0].value
            : ""; // Use an empty string for the value if no roles are found
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: newRoleId,
        }));
      } else {
        setRoleOptions([]);
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: "", // Clear dept_role_id if no department is selected
        }));
      }
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleSelectUser = (userId) => {
    setFormData((prevFormData) => {
      const updatedSelectedUsers = [...prevFormData.selectedUsers];

      if (updatedSelectedUsers.includes(userId)) {
        // Remove user from the array if already selected
        return {
          ...prevFormData,
          selectedUsers: updatedSelectedUsers.filter((id) => id !== userId),
        };
      } else {
        // Add user to the array if not already selected
        return {
          ...prevFormData,
          selectedUsers: [...updatedSelectedUsers, userId],
        };
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === userDetails.length) {
      setSelectedUsers([]); // Deselect all if already selected
    } else {
      setSelectedUsers(userDetails.map((user) => user.user_id)); // Select all users
    }
  };
  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/allorg");
      const organizationData = response.data.data;

      const mgOrganization = organizationData.find(
        (org) => org.org_name.toLowerCase() === "mg".toLowerCase()
      );

      if (mgOrganization) {
        const mgId = mgOrganization.id;

        // Set the default org_id to the found MG Id
        setFormData((prevData) => ({
          ...prevData,
          org_id: mgId,
        }));
      }
      setOrganizations(organizationData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  return (
    <Container className="task-form-container">
      <h2 className="text-center mb-4">Custom Task Setup</h2>
      <Form>
        <Row>
          {/* Organization Name Dropdown */}
          {/* <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="org_id">
              <Form.Label>
                Organization
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="org_id"
                value={formData.org_id}
                onChange={handleChange}
                className={`select-placeholder ${
                  formData.organization === "" ? "placeholder" : ""
                }`}>
                <option value="" style={{ color: "#525658" }}>
                  Select Organization
                </option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
              {errors.org_id && (
                <small className="text-danger">{errors.org_id}</small>
              )}{" "}
             
            </Form.Group>
          </Col> */}

          {/* Process Name Dropdown */}
          {/* <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="process_id">
              <Form.Label>
                Process Name
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="process_id"
                value={formData.process_id}
                onChange={handleChange}
                disabled={loading}>
                <option value="">Select Process</option>
                {processOptions.map((process) => (
                  <option key={process.key} value={process.value}>
                    {process.label}
                  </option>
                ))}
              </Form.Control>
       
              {errors.process_id && (
                <small className="text-danger">{errors.process_id}</small>
              )}
            </Form.Group>
          </Col> */}

          {/* Department Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_id">
              <Form.Label>
                Department
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // as="select"
                name="dept_id"
                value={formData.dept_id}
                onChange={handleChange}>
                <option value="">Select Department</option>
                {departmentOptions.map((dept) => (
                  <option key={dept.key} value={dept.value}>
                    {dept.label}
                  </option>
                ))}
              </Form.Select>
              {errors.dept_id && (
                <small className="text-danger">{errors.dept_id}</small>
              )}
            </Form.Group>
          </Col>

          {/* Department Role Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_role_id">
              <Form.Label>
                Department Role
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="dept_role_id"
                value={formData.dept_role_id}
                onChange={handleChange}
                disabled={roleOptions.length === 0}>
                <option value="">Select Department Role</option>
                {roleOptions.map((role, index) => (
                  <option key={`${role.value}-${index}`} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </Form.Select>
              {errors.dept_role_id && (
                <small className="text-danger">{errors.dept_role_id}</small>
              )}
            </Form.Group>
          </Col>
          <Col md={12} style={{ padding: "10px" }}>
            {formData.dept_id && formData.dept_role_id ? (
              userDetails.length ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>
                        {/* Master checkbox to select/deselect all users */}
                        <Form.Check
                          type="checkbox"
                          checked={selectedUsers.length === userDetails.length} // Ensure all users are selected
                          onChange={handleSelectAll}
                          label="Select All"
                        />
                      </th>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetails.map((user) => (
                      <tr key={user.user_id}>
                        <td>
                          {/* Checkbox for individual user selection */}
                          <Form.Check
                            type="checkbox"
                            checked={selectedUsers.includes(user.user_id)} // Check if this user is selected
                            onChange={() => handleSelectUser(user.user_id)} // Update on change
                          />
                        </td>
                        <td>{user.user_id}</td>
                        <td>{user.name}</td>
                        <td>{user.contact_number}</td>
                        <td>{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th colSpan="5" style={{ textAlign: "center" }}>
                        No Users Assigned
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", color: "red" }}>
                        There is no user assigned to this department and its
                        role. Please assign users.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )
            ) : null}
          </Col>

          {/* Task Name */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="task_name">
              <Form.Label>
                Task Name
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="task_name"
                value={formData.task_name}
                placeholder="Task Name"
                onChange={handleChange}
              />
            </Form.Group>
            {errors.task_name && (
              <small className="text-danger">{errors.task_name}</small>
            )}
          </Col>

          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="priority">
              <Form.Label>
                Task Priority
                <span className="text-danger">*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "space-around",
                }}>
                <Form.Check
                  type="radio"
                  id="priority-high"
                  name="priority"
                  label="High"
                  value="0"
                  checked={formData.priority === "0"}
                  onChange={handleChange}
                  style={{ color: "red" }}
                />
                <Form.Check
                  type="radio"
                  id="priority-medium"
                  name="priority"
                  label="Medium"
                  value="1"
                  checked={formData.priority === "1"}
                  onChange={handleChange}
                  style={{ color: "orange" }}
                />
                <Form.Check
                  type="radio"
                  id="priority-low"
                  name="priority"
                  label="Low"
                  value="2"
                  checked={formData.priority === "2"}
                  onChange={handleChange}
                  style={{ color: "green" }}
                />
              </div>
              {errors.priority && (
                <small className="text-danger">{errors.priority}</small>
              )}
            </Form.Group>
          </Col>

          <Col md={6} style={{ padding: "10px" }}>
            {/* Resolution Type Select */}
            <Form.Group controlId="resolution_type">
              <Form.Label>
                Target Type
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="resolution_type"
                value={formData.resolution_type}
                onChange={handleChange}
                aria-label="Select Resolution Type">
                <option value="">Choose...</option>
                <option value="0">Day</option>
                <option value="1">Hour</option>
              </Form.Select>
              {errors.resolution_type && (
                <small className="text-danger">{errors.resolution_type}</small>
              )}
            </Form.Group>
          </Col>

          {formData.resolution_type === "0" && (
            <Col md={6} style={{ padding: "10px" }}>
              <Form.Group controlId="days">
                <Form.Label>Select Number of Days</Form.Label>
                <Form.Select
                  name="days"
                  value={formData.days}
                  onChange={handleChange}>
                  <option value="">Choose...</option>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </Form.Select>
                {errors.days && (
                  <small className="text-danger">{errors.days}</small>
                )}
              </Form.Group>
            </Col>
          )}

          {formData.resolution_type === "1" && (
            <>
              <Col md={6} style={{ padding: "10px" }}>
                <Form.Group controlId="hours">
                  <Form.Label>Select Hours</Form.Label>
                  <Form.Select
                    name="hours"
                    value={formData.hours}
                    onChange={handleChange}>
                    <option value="">Choose...</option>
                    {Array.from({ length: 8 }, (_, i) => i + 1).map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.hours && (
                    <small className="text-danger">{errors.hours}</small>
                  )}
                </Form.Group>
              </Col>

              <Col md={6} style={{ padding: "10px" }}>
                <Form.Group controlId="minutes">
                  <Form.Label>Select Minutes</Form.Label>
                  <Form.Select
                    name="minutes"
                    value={formData.minutes}
                    onChange={handleChange}>
                    <option value="">Choose...</option>
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                  </Form.Select>
                  {errors.minutes && (
                    <small className="text-danger">{errors.minutes}</small>
                  )}
                </Form.Group>
              </Col>
            </>
          )}

          {/* SLA Resolution Time */}
          {/* <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="sla_resolution_time">
              <Form.Label>
                SLA Resolution Time
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                name="sla_resolution_time"
                value={formData.sla_resolution_time}
                onChange={handleChange}
              />
              {errors.sla_resolution_time && (
                <small className="text-danger">
                  {errors.sla_resolution_time}
                </small>
              )}
            </Form.Group>
          </Col> */}

          {/* Is Document Required Checkbox */}
          <Col md={6} style={{ padding: "50px 10px" }}>
            <Form.Group controlId="is_doc_upload_required">
              <Form.Check
                type="checkbox"
                label="Is Document Required"
                name="is_doc_upload_required"
                checked={formData.is_doc_upload_required}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          {/* <Col lg={6} md={6} sm={12}>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>
                Task Status
              </Form.Label>
              <span className="text-danger" style={{ fontSize: "0.85em" }}>
                *
              </span>
              <Form.Select
                value={taskStatus}
                onChange={handleStatusChange}
                className="form-control">
                <option value={0}>Open</option>
                <option value={1}>Completed</option>
                <option value={2}>Reassign</option>
                <option value={3}>Rejected</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

          {/* Task Description */}
          <Col md={12} style={{ padding: "10px" }}>
            <Form.Group controlId="task_description">
              <Form.Label>Task Description (if any)</Form.Label>
              <Form.Control
                as="textarea"
                name="task_description"
                rows={4}
                value={formData.task_description}
                onChange={handleChange}
              />
            </Form.Group>
            {taskStatus === "2" && (
              <div>
                <label htmlFor="additionalInfo">Additional Information</label>
                <input type="text" id="additionalInfo" name="additionalInfo" />

                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Reassign To
                  </Form.Label>
                  <span className="text-danger" style={{ fontSize: "0.85em" }}>
                    *
                  </span>
                  <Form.Select
                    // value={reassignUser}
                    // onChange={handleReassignChange}
                    className="form-control">
                    <option value="">Select User</option>
                    {users.length > 0 ? (
                      users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))
                    ) : (
                      <option>No users available</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </div>
            )}
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="form-actions mb-4">
          <Button
            variant="outline-light"
            type="reset"
            className="cancel-btn"
            onClick={handleClose}>
            Cancel Task
          </Button>
          <Button
            variant="outline-light"
            // type="submit"
            className="create-btn"
            disabled={selectedUsers.length === 0}
            onClick={() => handleSubmit(TaskNumberFlow, formData)}>
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CustomTaskForm;

// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import "./SidebarComponents.css";
// import { useDispatch, useSelector } from "react-redux";
// import { selectIsSidebarOpen } from "../../redux/SideBarSlice";

// export const SidebarMenuItem = ({
//   icon: Icon,
//   path,
//   name,
//   submenu,
//   isActive,
//   activeItem,
//   setActiveItem,
//   openSubmenu,
//   setOpenSubmenu,
//   index, 
// }) => {
//   const [ismenuOpen, setIsmenuOpen] = useState(false);
//   const navigate = useNavigate();

//   const isOpen = useSelector(selectIsSidebarOpen);



  

//   // const handleToggle = (e, path) => {
//   //   e.preventDefault();
//   //   // Toggle submenu visibility if the menu item has a submenu
//   //   if (submenu) {
//   //     setIsmenuOpen((prev) => !prev);
//   //   } else {
//   //     // Set active item only if it's not a submenu parent
//   //     setActiveItem(path);
//   //     navigate(path);
//   //   }
//   // };
//   const handleToggle = (e, path) => {
//     e.preventDefault();

//     // If the current menu item has a submenu
//     if (submenu) {
//       // If the current submenu is already open, close it
//       if (openSubmenu === index) {
//         setOpenSubmenu(null); // Close submenu
//       } else {
//         setOpenSubmenu(index); // Open the submenu corresponding to the clicked index
//       }
//     } else {
//       // If there's no submenu, just set active and navigate
//       setActiveItem(path);
//       navigate(path);
//     }
//   };

//   const isSubmenuOpen = openSubmenu === index;

//   // Determine if the current submenu item is active
//   const isSubmenuActive = submenu?.some(
//     (subitem) => subitem.path === activeItem
//   );

//   return (
//     <div className={`menuItem mb-3 ${isActive || isSubmenuActive ? "active" : ""}`}>
//       <Link
//         to={path || "#"}
//         className="menuItem-link"
//         onClick={(e) => handleToggle(e, path)} // Handle submenu toggle on link click
//       >
//         <div className="menuItem-content d-flex align-items-center menu_item">
//           <Icon className="menuItem-icon" />
//           {isOpen && (
//             <p className="menuItem-name mb-0 text-center flex-grow-1">{name}</p>
//           )}
//         </div>
//       </Link>

//       {/* Render submenu if it exists and the submenu is open */}
//       {submenu && isSubmenuOpen && (
//         <div className="submenu">
//           {submenu.map((subitem, subindex) => (
//             <Link
//               to={subitem.path}
//               key={subindex}
//               className={`submenu-item ${subitem.path === activeItem ? "active" : ""}`} // Apply active class to the active submenu item
//               onClick={() => {
//                 setActiveItem(subitem.path); // Set active submenu item
//                 navigate(subitem.path); // Navigate to submenu path
//                 setOpenSubmenu(null); // Close submenu after selecting
//               }}
//             >
//               <div className="d-flex align-items-center sbmenu_item">
//                 <subitem.icon className="submenuItem-icon" />
//                 {isOpen && <p style={{ fontSize: "16px" }} className="menuItem-name mb-0">{subitem.name}</p>}
//               </div>
//             </Link>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SidebarComponents.css";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpen } from "../../redux/SideBarSlice";

export const SidebarMenuItem = ({
  icon: Icon,
  path,
  name,
  submenu,
  isActive,
  activeItem,
  setActiveItem
}) => {
  const [ismenuOpen, setIsmenuOpen] = useState(false);
  const navigate = useNavigate();

  const isOpen = useSelector(selectIsSidebarOpen);



  

  const handleToggle = (e, path) => {
    e.preventDefault();
    // Toggle submenu visibility if the menu item has a submenu
    if (submenu) {
      setIsmenuOpen((prev) => !prev);
    } else {
      // Set active item only if it's not a submenu parent
      setActiveItem(path);
      navigate(path);
    }
  };

  // Determine if the current submenu item is active
  const isSubmenuActive = submenu?.some(
    (subitem) => subitem.path === activeItem
  );

  return (
    <div
      className={`menuItem mb-3 ${
        isActive || isSubmenuActive ? "active" : ""
      }`}>
      <Link
        to={path || "#"}
        className="menuItem-link"
        onClick={(e) => handleToggle(e, path)}>
        <div className="menuItem-content d-flex align-items-center menu_item">
          <Icon className="menuItem-icon" />
         {isOpen && <p className="menuItem-name mb-0 text-center flex-grow-1">{name}</p> }
        </div>
      </Link>
      {/* Render submenu if exists and is open */}
      {submenu && ismenuOpen && (
        <div className="submenu">
          {submenu.map((subitem, index) => (
            <Link
              to={subitem.path}
              key={index}
              className={`submenu-item ${
                subitem.path === activeItem ? "active" : ""
              }`} // Apply active class to active submenu item
              onClick={() => {
                setActiveItem(subitem.path); // Set active submenu item
                navigate(subitem.path); // Navigate to submenu path
              }}>
              <div className="d-flex align-items-center sbmenu_item">
                <subitem.icon className="submenuItem-icon" />
             {isOpen &&   <p style={{ fontSize:'16px' }} className="menuItem-name mb-0">{subitem.name}</p> }
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};


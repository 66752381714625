import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Badge,
  Modal,
} from "react-bootstrap";
import { Ticket } from "../components/DashComponents/Ticket";
import AddCustomTask from "../components/FormComponents/TaskForm/AddCustomTask";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";

import {
  FcAddDatabase,
  FcList,
  FcProcess,
  FcCheckmark,
  FcExpired,
} from "react-icons/fc";
import Charts from "../components/DashComponents/Data/Chart";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import URL from "../API/Api";

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

// TicketData
const ticketData = [
  {
    name: "New Tasks",
    count: 2,
    icon: <FcAddDatabase />,
    background:
      "linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))",
    Graphbackground: ["rgb(250, 202, 207)", "rgb(250, 202, 207)"],
  },
  {
    name: "Dependency Tasks",
    count: 17,
    icon: <FcList />,
    background: "linear-gradient(to right, #bbdefb, #e3f2fd)",
    Graphbackground: ["rgb(187, 222, 251)", "rgb(187, 222, 251)"],
  },
  {
    name: "Reschedule Tasks",
    count: 10,
    icon: <FcProcess />,
    background:
      "linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))",
    Graphbackground: ["rgb(247, 212, 253)", "rgb(247, 212, 253)"],
  },
  {
    name: "Closed Tasks",
    count: 9,
    icon: <FcCheckmark />,
    background:
      "linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))",
    Graphbackground: ["rgb(178, 251, 194)", "rgb(178, 251, 194)"],
  },

  {
    name: "Rejected Tasks",
    count: 10,
    icon: <FcExpired />,
    background:
      "linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))",
    Graphbackground: ["rgb(255, 227, 171)", "rgb(255, 227, 171)"],
  },
];

const Dashboard = () => {
  const location = useLocation();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [FlowTask, setFlowTask] = useState([]);
  const [parsedNotifications, setparsedNotifications] = useState([]);
  const [secondparsedNotifications, setSecondparsedNotifications] = useState([]);
  
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({
    status: 0,
  });
  const [notificationData, setNotificationData] = useState({});
  const [taskStatus, setTaskStatus] = useState(); // Store the current task status
  const [taskRemarks, setTaskRemarks] = useState(""); // Store the current task status
  const [taskResponseMsg, setTaskResponseMsg] = useState(""); // Store the current task status
  const [initialStatus, setInitialStatus] = useState(""); // Track the initial status for comparison
  const [isStatusChanged, setIsStatusChanged] = useState(false); // Track if the status has changed
  const [reassignUser, setReassignUser] = useState("");
  const [users, setUsers] = useState([]);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editSLA, seteditSLA] = useState("");

  const [isSLAResolutionHour, setisSLAResolutionHour] = useState(0);
  const [Thours, setHours] = useState(''); // To store hours if SLA is based on hours
  const [Tminutes, setMinutes] = useState(''); // To store minutes if SLA is based on hours
  const [divert, setDivert] = useState("");


  const [ RescheduleAcceptModal , setRescheduleAcceptModal]=useState(false);
  const [ RescheduleRejectModal , setRescheduleRejectModal]=useState(false);
  // const storedNotification = localStorage.getItem("userNotification");
  const getUser = localStorage.getItem("user");
  const parsedUser = JSON.parse(getUser);
  // const parsedNotifications = JSON.parse(storedNotification);

  useEffect(() => {
    NotificationCall(org_dept_id);
  }, [modalVisible]);

  useEffect(() => {
    NotificationCall();
  }, []);

  const HandleModalofReschedule =(data) =>{
    setRescheduleAcceptModal(true);

  }

  const HandleModalofRescheduleReject =(data) =>{
    setRescheduleRejectModal(true);

  }

  //Notification
  const NotificationCall = (org_dept_id) => {
    const orgDeptId =
      org_dept_id !== undefined
        ? org_dept_id
        : JSON.parse(localStorage.getItem("departments"));
    const payload = {
      user_id: parsedUser?.data.id,
      org_dept_id: orgDeptId,
    };

    // console.log("one", payload);
    const params = new URLSearchParams(payload).toString();
    // console.log(
    //   `/api/taskConfigDetailsResponse/getUserNotifications?${params}`,
    //   "one"
    // );
    URL.get(`/api/v1/taskConfigDetailsResponse/getUserNotifications?${params}`)
      .then((res) => {
        console.log(res.data.data, "twoViiew");
        setparsedNotifications(res.data.data);
        setSecondparsedNotifications(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const org_dept_id = location.state?.org_dept_id;

  useEffect(() => {
    if (org_dept_id) {
      NotificationCall(org_dept_id);
    }
  }, [org_dept_id]);

  const handleReassignChange = (e) => {
    // console.log("Selected user ID:", e.target.value); // Debugging line
    setReassignUser(e.target.value);
    // console.log("resuser",reassignUser)
  };

  // console.log(users,"manomani")
  const fetchUsers = async (selectedNotification) => {
    try {
      //  const taskId = selectedNotification?.task?.id;
      const dept_id = selectedNotification?.department.id;
      const dept_role_id = selectedNotification?.department_role.id;
      // console.log(dept_role_id, "dept_role_id");
      const response = await URL.get("/api/task/usersData", {
        params: {
          dept_id: dept_id,
          dept_role_id: dept_role_id,
        },
      });

      if (response.data.status) {
        setUsers(response.data.data); // Set users from API response
        // console.log(users, "tkkk");
      } else {
        console.error("Error fetching users:", response.data.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  };
  useEffect(
    (selectedNotification) => {
      // console.log("selectedNotification.status ", selectedNotification.status);
      // Ensure selectedNotification is an object and status exists
      if (selectedNotification && taskStatus === "2") {
        fetchUsers(selectedNotification); // Fetch users when Reassign is selected
      }
    },
    [taskStatus]
  );

  // task_config_detail.id 
  console.log(parsedNotifications,"parsedNotifications")
  useEffect(() => {
    if (modalVisible && selectedNotification) {
      // Filter notifications based on task_id and process_id
      const history = parsedNotifications.filter((notification) => {
        return (
          notification.task.id === selectedNotification.task.id &&
          notification.process_id === selectedNotification.process_id
        );
      });

      // Filter notifications based on the selected task_config_id
const filteredNotifications = secondparsedNotifications.filter(
  (x) => x.task_config_detail?.id === selectedNotification.task_config_detail.id
);

console.log(filteredNotifications,"fltyuip")

// Sort the filtered history by `created_at`
const sortedHistory = filteredNotifications.sort((a, b) => {
  return moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1;
});

      // Sort history by created_at (ascending order)
      // const sortedHistory = history.sort((a, b) => {
      //   return moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1;
      // });

      console.log(selectedNotification.task_config_detail.id,"sortHisotry0")
      console.log(parsedNotifications.map((x)=>x.task_config_detail.id)
        ,"sortHisotry")
        // console.log(selectedNotification.task_config_detail.task_config_id,"sortHisotry2")

      console.log(sortedHistory,"mysort")
      // Map data to the desired format
      const formattedHistory = sortedHistory.map((notification) => ({
        user: notification.userAssignedByDetail.name,
        usermail: notification.userAssignedByDetail.email,
        assignedTo: notification.assigned_to,
        assignedToName: notification.task_assignee_name,
        remarks: notification.remarks,
        status: notification.status,
        tName:notification.task[0]?.task_name,
        createdAt: moment(notification.created_at).format(
          "MMMM Do YYYY, h:mm A"
        ), // Human-readable date format
        finalStatus: getBadgeInfo(notification.status),
      }));

      setTaskHistory(formattedHistory);
    }
  }, [modalVisible, selectedNotification, parsedNotifications]);


  console.log(taskHistory,"myparsing");
  const getBadgeInfo = (status) => {
    switch (status) {
      case 0:
        return { text: "Open", color: "secondary" };
      case 1:
        return { text: "Completed", color: "secondary" };
      case 2:
        return { text: "Reassigned", color: "secondary" };
      case 3:
        return { text: "Rejected", color: "secondary" };
        case 4:
          return { text: "Reschedule Request", color: "secondary" };
          case 5:
            return { text: "Reschedule Accepted", color: "secondary" };
            case 6:
              return { text: "Reschedule Cancelled", color: "secondary" };
      default:
        return { text: "Unknown", color: "secondary" };
    }
  };

  //createGradient
  const createGradient = (ctx, colors) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    colors.forEach((color, index) => {
      const rgbaMatch = color.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d?\.?\d*))?\)/
      );
      if (rgbaMatch) {
        const [_, r, g, b, a] = rgbaMatch;
        gradient.addColorStop(
          index / (colors.length - 1),
          `rgba(${r}, ${g}, ${b}, ${a ? a : 1})` // Default alpha to 1 if not defined
        );
      } else {
        console.warn(`Invalid color format: ${color}`);
      }
    });

    return gradient;
  };

  // chart data
  const chartData = {
    labels: ticketData.map((ticket) => ticket.name),
    datasets: [
      {
        label: "Tasks",
        data: ticketData.map((ticket) => ticket.count),
        backgroundColor: ticketData.map((ticket) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          return createGradient(ctx, ticket.Graphbackground);
        }),
      },
    ],
  };

  const [myLastIndexTask,setmyLastIndexTask]=useState()
  // Function to open the modal and set the selected notification
  const openModal = (notification) => {
    console.log(notification, "gg");
    setSelectedNotification(notification);
    fetchUsers(notification);


    

    const LastIndexNotification= notification?.task;
    const MyLast= LastIndexNotification[LastIndexNotification.length-1];
    console.log(MyLast,"lastindex")
    setmyLastIndexTask(MyLast)



    setNotificationData(notification);
    setModalVisible(true);
    const org_dept_id = notification?.task?.department?.org_dept_id;
    let TaskUpdateData = {
      is_read: 1,
    };
    // api/taskConfigDetailsResponse/11/updateIsReadStatus
    URL.put(
      `/api/taskConfigDetailsResponse/${notification.id}/updateIsReadStatus`,
      TaskUpdateData
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    if (org_dept_id) {
      NotificationCall(org_dept_id);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setModalVisible(false);
    setTaskStatus();
    setTaskRemarks();
    setTaskResponseMsg();
    setImagePreview();
    setSelectedNotification(null); // Clear the selected notification
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    // console.log(newStatus, "rt");
    // setSelectedNotification({ status: newStatus });
    // setSelectedNotification({ ...selectedNotification, status: newStatus });
    setTaskStatus(newStatus);
    setIsStatusChanged(true);
  };

  // console.log(users,"userFetching");
  const [imagePreview, setImagePreview] = useState("");

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setUploadedDocument(file);
        setImagePreview(reader.result); // Data URL of the image
      };

      reader.readAsDataURL(file); // Read the file as a data URL (base64 string)
    }
  };


  const AcceptRescheduleHandler =(doneR)=>{
    console.log(selectedNotification,"ssg",doneR)

    const TaskUpdateData ={
      task_id: myLastIndexTask.id,
      response_id: selectedNotification?.id,
      is_accepted_by_owner: 1,
      status: 5
    }
    URL.put(
      `/api/taskConfigDetailsResponse/ownerTaskResponse`,
      TaskUpdateData
    )
      .then((res) =>{ console.log(res)
        setRescheduleAcceptModal(false);
      })
      .catch((err) => console.log(err));
  }

  const RejectRescheduleHandler =(doneR)=>{

    const TaskUpdateData ={
      task_id: myLastIndexTask.id,
      response_id: selectedNotification.id,
      is_accepted_by_owner: 0,
      status: 6
    }
    URL.put(
      `/api/taskConfigDetailsResponse/ownerTaskResponse`,
      TaskUpdateData
    )
      .then((res) =>{ console.log(res)
        setRescheduleRejectModal(false)
      })
      .catch((err) => console.log(err));
  }

  const handleResolutionChange = (e) => {
    const value = e.target.value;
    setisSLAResolutionHour(value);
  
    // If switching to hours, initialize Thours and Tminutes
    if (value === "1") {
      // Convert `editSLA` to hours and minutes if it exists
      const [hours, minutes] = editSLA.split(":").map((num) => parseInt(num, 10));
      setHours(hours || 1);  // Default to 1 if no valid hours found
      setMinutes(minutes || 0);  // Default to 0 if no valid minutes found
    } else {
      seteditSLA("");  // Reset editSLA if not in hours mode
    }
  };


  const handleStatusUpdate = async () => {
    setIsSubmitting(true);
    const taskId = selectedNotification.id;
    const formData = new FormData();

    if(taskStatus==4) {

      setDivert(`${Thours}:${Tminutes}`)
     
  
      let slaResolutionTime = editSLA; // Default to current value of editSLA
   if ((isSLAResolutionHour === 1 || isSLAResolutionHour === "1") && Thours && Tminutes) {
        slaResolutionTime = `${Thours}:${Tminutes}`; // Combine hours and minutes
      } else if (isSLAResolutionHour === '0' || isSLAResolutionHour === 0) {
        slaResolutionTime = editSLA;
      }

      formData.append("task_name", selectedNotification.task.task_name);
      formData.append(
        "dep_name",
        selectedNotification.department.department_name
      );
      formData.append(
        "dep_role_name",
        selectedNotification.department_role.dep_role_name
      );
      formData.append(
        "slaResolution",
        selectedNotification.task[0].sla_resolution_time
      );
      formData.append("due_date", selectedNotification.task[0]?.due_date);
      formData.append(
        "task_description",
        selectedNotification.task[0].task_description
      );
      formData.append("status", taskStatus);
      formData.append("remarks", taskRemarks);
      formData.append("response_message", taskResponseMsg);
      formData.append(
        "task_config_detail_id",
        selectedNotification.task_config_detail.id
      );
      // formData.append(
      //   "task_flow_order",
      //   selectedNotification?.task.task_flow_order
      // );
      formData.append("process_id", selectedNotification?.process.id);
      formData.append("task_id", selectedNotification?.task[0].id);
      formData.append("user_id", parsedUser.data?.id);
      // formData.append("assigned_to", reassignUser);
      formData.append("sla_resolution_time", slaResolutionTime);
      formData.append("is_resolution_hour", isSLAResolutionHour);
      // formData.append("assigned_to", reassignUser);
      // formData.append("assigned_to", reassignUser);
      // console.log("assigned_to", reassignUser);
    formData.append("task_type", "custom");

  
      // Append image if available
      if (uploadedDocument) {
        formData.append("uploaded_document", uploadedDocument);
      }


      // for reschedule
    try {
      const response = await URL.post(
        `api/taskConfigDetailsResponse/${taskId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ); 
      setTaskStatus();
      setTaskRemarks();
      setTaskResponseMsg();
      setImagePreview();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Task updated successfully!",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745", // green background for success
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setIsSubmitting(false)

      closeModal();
    } catch (error) {
      console.error("Error uploading the data", error);
      setIsSubmitting(false)
 
      const errorMessage =
        error?.response?.data?.errors?.[0] ||
        error?.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
 
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545", // Red background for error
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }


    } else {


    console.log("mathana",taskStatus)

    // Append text fields
    formData.append("task_name", selectedNotification.task.task_name);
    formData.append(
      "dep_name",
      selectedNotification.department.department_name
    );
    formData.append(
      "dep_role_name",
      selectedNotification.department_role.dep_role_name
    );
    formData.append(
      "slaResolution",
      selectedNotification.task[0].sla_resolution_time
    );
    formData.append("due_date", selectedNotification.task[0].due_date);
    formData.append(
      "task_description",
      selectedNotification.task[0].task_description
    );
    formData.append("status", taskStatus);
    formData.append("remarks", taskRemarks);
    formData.append("response_message", taskResponseMsg);
    formData.append(
      "task_config_detail_id",
      selectedNotification.task_config_detail.id
    );
    // formData.append(
    //   "task_flow_order",
    //   selectedNotification?.task.task_flow_order
    // );
    formData.append("process_id", selectedNotification?.process.id);
    formData.append("task_id", selectedNotification?.task[0].id);
    formData.append("user_id", parsedUser.data?.id);
    formData.append("assigned_to", reassignUser);
    formData.append("task_type", "custom");
    // console.log("assigned_to", reassignUser);

    // Append image if available
    if (uploadedDocument) {
      formData.append("uploaded_document", uploadedDocument);
    }

    // console.log(formData, "manmathan");

      // except  reschedule
    try {
      const response = await URL.post(
        `api/taskConfigDetailsResponse/${taskId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ); 
      setTaskStatus();
      setTaskRemarks();
      setTaskResponseMsg();
      setImagePreview();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Task updated successfully!",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745", // green background for success
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setIsSubmitting(false)


      closeModal();
    } catch (error) {
      console.error("Error uploading the data", error.response.data.errors[0]);
 
      const errorMessage =
        error?.response?.data?.errors?.[0] ||
        error?.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
        setIsSubmitting(false)
      
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545", // Red background for error
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }
  };

  const formatDueDate = () => {
    const { sla_resolution_time, is_resolution_hour } =
      selectedNotification.task;

    if (is_resolution_hour === 0) {
      // If is_resolution_hour is 0, it's a day
      const dayCount = sla_resolution_time;
      return `${dayCount} day${dayCount > 1 ? "s" : ""}`;
    } else if (is_resolution_hour === 1) {
      // If is_resolution_hour is 1, it's time in hours and minutes
      const timeParts = sla_resolution_time.split(":");
      const hours = timeParts[0];
      const minutes = timeParts[1] || 0;
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} min${
        minutes > 1 ? "s" : ""
      }`;
    }
    return "";
  };

  const getNotificationMessage = (item) => {
    const { status,task_assignee_name,userAssignedByDetail, is_reassigned,search_from, task,process,department, assigned_to } = item;

    const taskName = task[0]?.task_name;
    const processName = process?.process_name;
    const departmentName = department?.department_name;
    const AssignedOrRequestFrom =userAssignedByDetail?.name;
    const AssignedTo = task_assignee_name;
    const YourActivityAction = search_from;
    if (status === 0 && YourActivityAction==="assigned_user") {
      return (
        <>
          You received the task <b>{taskName}</b> under the <b>{processName} </b>{" "}
          process for {departmentName} department assigned by {AssignedOrRequestFrom}.
        </>
      );
    }
    if (status === 0 && YourActivityAction==="user") {
      return (
        <>
          You assigned the <b>{taskName}</b> task under the {processName}{" "}
          process for {departmentName} department to {AssignedTo}.
        </>
      );
    }

    if (status === 1 && YourActivityAction==="assigned_user") {
      return (
        <>
          The <b>{taskName}</b> task under the {processName} process has been
          marked as closed by {AssignedOrRequestFrom}.
        </>
      );
    }

    if (status === 1 && YourActivityAction==="user") {
      return (
        <>
          The <b>{taskName}</b> task under the {processName} process has been
          marked as closed by you.
        </>
      );
    }



    if (status === 2 && is_reassigned === 1) {
      return (
        <>
          You reassigned the <b>{taskName}</b> task under the {processName}{" "}
          process to {AssignedTo}.
        </>
      );
    }

    if (status === 2) {
      return (
        <>
          You reassigned the <b>{taskName}</b> task under the {processName}{" "}
          process to the {departmentName} department.
        </>
      );
    }

    if (status === 3) {
      return (
        <>
          You rejected the <b>{taskName}</b> task under the {processName}{" "}
          process.
        </>
      );
    }

    if (status === 4) {
      return (
        <div>
          You received the reschedule request  of <b>{taskName}</b> under the {processName}{" "}
          process from  <b> {AssignedOrRequestFrom} </b>.
        </div>
      );
    }
    if (status === 5) {
      return (
        <>
          Reschedule has been accepted <b>{taskName}</b> task under the {processName}{" "}
          process.
        </>
      );
    }
    if (status === 6) {
      return (
        <>
          Reschedule has been cancelled <b>{taskName}</b> task under the {processName}{" "}
          process.
        </>
      );
    }



    return <>{"Notification: No specific message defined."}</>;
  };

  //custom form
  const handleCloseTask = () => {
    setShowTaskModal(false);
  };

  const handleCloseReschedule= ()=>{
    setRescheduleAcceptModal(false);
  }

  const handleCloseRejectReschedule= ()=>{
    setRescheduleRejectModal(false);
  }
  const handleShowTask = () => setShowTaskModal(true);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}>
        {" "}
        <Button
          onClick={handleShowTask}
          style={{
            background:
              "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
            border: "none",
          }}>
          + Custom Task
        </Button>
      </div>

      <Row>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                New <br /> Tasks{" "}
              </>
            }
            count="15"
            icon={<FcAddDatabase />}
            background="linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Dependency <br /> Tasks{" "}
              </>
            }
            count="20"
            icon={<FcList />}
            background="linear-gradient(to right, #bbdefb, #e3f2fd)"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Reschedule <br /> Tasks{" "}
              </>
            }
            count="11"
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Closed <br /> Tasks{" "}
              </>
            }
            count="56"
            icon={<FcCheckmark />}
            background="linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Rejected <br />
                Tasks{" "}
              </>
            }
            count="0"
            icon={<FcExpired />}
            background="linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Delayed <br /> Tasks{" "}
              </>
            }
            count="2"
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
      </Row>

      <Row>
        {/* Tabular Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          {/* <div className="chart-container p-2 m-2">
            {parsedNotifications ? <> {parsedNotifications?.map((item, index) => ( 
              <div key={index} 
              onClick={() => openModal(item)}
              style={{ cursor: 'pointer' }}
              className="alert alert-info"> 
             <b> A new task Alert : </b> A task {item.task.task_name} under the process of {item.process.process_name}. </div>
            )) }</> : (
              <div>No new notifications.</div>
            )}
          </div> */}
          <div className=" m-2 p-2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}>
              <h5>Task Updates</h5>
              <div>
                <span>
                  Open :{" "}
                  <b>
                    {parsedNotifications &&
                      parsedNotifications.filter((c) => c.status == 0)
                        ?.length}{" "}
                  </b>
                </span>
                <span>
                  Total :{" "}
                  <b> {parsedNotifications && parsedNotifications.length} </b>{" "}
                </span>
              </div>
            </div>
            {parsedNotifications ? (
              <div style={{ height: "60vh", overflowY: "auto" }}>
                {parsedNotifications
                  ?.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  ) // Sorting by created_at to display the latest notifications first
                  .map((item, index) => {
                    const { text: badgeText, color: badgeColor } = getBadgeInfo(
                      item.status
                    );

                    return (
                      <div className="notification_card" key={index}>
                        <div
                          className="card"
                          onClick={() => openModal(item)}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            transition: "box-shadow 0.3s ease",
                            marginBottom: "15px",
                          }}>
                          {item.is_read === 0 ? (
                            <div
                              className="card-body"
                              style={{
                                padding: "20px",
                                backgroundColor: "#ffffff",
                                borderLeft: "5px solid #007bff",
                              }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "70%" }}>
                                      <div
                                        className="card-title"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}>
                                        {/* Priority Badge */}
                                        <Badge
                                          bg={
                                            item.task.priority === 0
                                              ? "danger" // red
                                              : item.task.priority === 1
                                              ? "warning" // orange
                                              : "success" // green
                                          }
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "12px",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                          }}>
                                          {item.task.priority === 0 &&
                                            "Time-Sensitive"}
                                          {item.task.priority === 1 &&
                                            "Moderate"}
                                          {item.task.priority === 2 &&
                                            "Can Wait"}
                                        </Badge>
                                        {getNotificationMessage(item)}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        width: "30%",
                                        textAlign: "right",
                                      }}>
                                      <Badge
                                        bg={badgeColor}
                                        style={{
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => openModal(item)}>
                                        {badgeText}
                                      </Badge>
                                      <div
                                        style={{
                                          textAlign: "right",
                                          fontSize: "smaller",
                                          marginTop: "5px",
                                          color: "#777",
                                        }}>
                                        <i>
                                          {item.status === 0
                                            ? `Created ${formatDistanceToNow(
                                                parseISO(item.created_at),
                                                { addSuffix: true }
                                              )}`
                                            : `Updated ${formatDistanceToNow(
                                                parseISO(item.updated_at),
                                                { addSuffix: true }
                                              )}`}
                                        </i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div
                              className="card-body"
                              style={{
                                padding: "20px",
                                backgroundColor: "#e5eaee",
                                borderLeft: "5px solid #ccc",
                              }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "70%" }}>
                                      <div
                                        className="card-title"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}>
                                        {/* Priority Badge */}
                                        <Badge
                                          bg={
                                            item.task.priority === 0
                                              ? "danger" // red
                                              : item.task.priority === 1
                                              ? "warning" // orange
                                              : "success" // green
                                          }
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "12px",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                          }}>
                                          {item.task.priority === 0 &&
                                            "Time-Sensitive"}
                                          {item.task.priority === 1 &&
                                            "Moderate"}
                                          {item.task.priority === 2 &&
                                            "Can Wait"}
                                        </Badge>
                                        {getNotificationMessage(item)}
                                      </div>
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      <Badge
                                        bg={badgeColor}
                                        style={{
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => openModal(item)}>
                                        {badgeText}
                                      </Badge>
                                      <div
                                        style={{
                                          textAlign: "right",
                                          fontSize: "smaller",
                                          marginTop: "5px",
                                          color: "#777",
                                        }}>
                                        <i>
                                          {item.status === 0
                                            ? `Created ${formatDistanceToNow(
                                                parseISO(item.created_at),
                                                { addSuffix: true }
                                              )}`
                                            : `Updated ${formatDistanceToNow(
                                                parseISO(item.updated_at),
                                                { addSuffix: true }
                                              )}`}
                                        </i>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>No new notifications.</div>
            )}
          </div>
        </Col>

        {/* Charts Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          <Charts chartData={chartData} />
        </Col>
      </Row>

      {/* Modal to view the notification details */}
      {modalVisible && selectedNotification && (
        <div className="modal-overlay">
          <div
            className="modal-content"
            style={{
              background: "#fff",
              height:"85vh", overflowY:'auto',
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}>
            <h4 className="text-center mb-4">Task Detail</h4>
            {selectedNotification && (
              <div>
                <Row>
                  {/* Process Name */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group style={{ fontWeight: "bold" }}>
                      <Form.Label>Process Name</Form.Label>
                      <Form.Control
                        type="text" disabled
                        value={selectedNotification.process.process_name}
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* Task Name */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Task
                      </Form.Label>
                      <Form.Control disabled
                        type="text"
                        value={selectedNotification.task[0].task_name}
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* Department */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Department
                      </Form.Label>
                      <Form.Control disabled
                        type="text"
                        value={
                          selectedNotification.department.department_name
                        }
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* Department Role */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Department Role
                      </Form.Label>
                      <Form.Control disabled
                        type="text"
                        value={
                          selectedNotification.department_role
                            .dep_role_name
                        }
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* SLA Resolution */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        SLA Resolution
                      </Form.Label>
                      <Form.Control disabled
                        type="text"
                        value={selectedNotification.task[0].is_resolution_hour===1 ?"Hour" :"Day"}
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* Due Date */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Due
                      </Form.Label>
                      <Form.Control disabled
                        type="text"
                        // value={formatDueDate()}
                        value={selectedNotification.task[0].sla_resolution_time}
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>

                  {/* Task Description */}
                  <Col lg={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Task Description
                      </Form.Label>
                      <Form.Control disabled
                        as="textarea"
                        value={selectedNotification.task[0].task_description}
                        readOnly
                        style={{ cursor: "not-allowed" }}
                        rows={3}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  {/* Uploaded Document */}
                  <Col lg={6} md={6} sm={12}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Uploaded Document
                      </Form.Label>
                      {/* <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}>
                        *
                      </span> */}
                      <div className="upload-doc-container">
                        <input  
                          type="file"
                          onChange={handleFileChange}
                          className="form-control"
                        />
                        {selectedNotification.status === 0 ? (
                          <div style={{ marginTop: "10px" }}>
                            <strong>Selected File:</strong>
                            {imagePreview && (
                              <img
                                src={imagePreview}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                selectedNotification.uploaded_document
                              }
                              alt=""
                              style={{
                                width: "100px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Task Status */}
                  <Col lg={6} md={6} sm={12}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Task Progress
                      </Form.Label>
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}>
                        *
                      </span>
                      <Form.Select style={{ height:'36.5px' }}
                        value={ 
                             taskStatus 
                        }
                        onChange={handleStatusChange}
                        // disabled={selectedNotification.status === "1"}
                        // readOnly={selectedNotification.status === "1"}
                        className="form-control">
                        { selectedNotification.status==4  &&  <option value={0} disabled>Open</option> }
                        <option >Choose Status</option>
                        <option value={1}>Completed</option>
                        <option value={2}>Dependency</option>
                        { selectedNotification.status==4  ?   <option  value={4} disabled >Reschedule Requested</option> :  <option  value={4}>Reschedule Requested</option> }
                          <option value={5}>Reschedule Accepted</option> 
                           <option value={6}>Reschedule Cancel</option> 
                        <option value={3}>Rejected</option>
                      </Form.Select>
                    </Form.Group>
                   {/* {selectedNotification.status==4 && <small>Reschedule Requested</small> } */}
                    {taskStatus === "2" && (
                      <Form.Group>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Reassign To
                        </Form.Label>
                        <span
                          className="text-danger"
                          style={{ fontSize: "0.85em" }}>
                          *
                        </span>
                        <Form.Select
                          value={reassignUser}
                          onChange={handleReassignChange}
                          className="form-control">
                          <option value="">Select User</option>
                          {users.length > 0 ? (
                            users.map((user) => (
                              <option key={user.user_id} value={user.user_id}>
                                {user.name}
                              </option>
                            ))
                          ) : (
                            <option>No users available</option>
                          )}
                        </Form.Select>
                      </Form.Group>
                    )}

{ ( selectedNotification.status === 4  || selectedNotification.status === 5  ) && (
                      <Row>
                              {/* <Col md={3} style={{ padding: "10px" }}>
                    <Form.Group controlId="is_resolution_hour">
                    <Form.Label>Resolution Type</Form.Label>
                      <Form.Select  style={{height:"36.5px"}}
                        value={myLastIndexTask.is_resolution_hour} 
                        
                        disabled 
                        aria-label="Select an option"
                      >
                        <option value="">Choose...</option>
                        <option value={0}>Day</option>
                        <option value={1}>Hour</option>
                      </Form.Select>
                    </Form.Group>
                </Col> */}

                {/* { (selectedNotification.status==4 || selectedNotification.status==5) && 
                  <Col md={3} style={{ padding: "10px" }}>
                  <Form.Group controlId="sla_resolution_time">
                    <Form.Label>Resolution Time</Form.Label>
                    <Form.Control 
                      name="sla_resolution_time"
                      value={myLastIndexTask.sla_resolution_time} 
                        disabled 
                    />
                  </Form.Group>
                </Col>
                } */}
                  { (selectedNotification.status==4 ) && 
                    <Col lg={6} >
                        <Badge  className="mx-1 cursor_pointer" onClick={()=>HandleModalofReschedule(selectedNotification)} >Accept</Badge>
                        <Badge className="cursor_pointer" onClick={()=>HandleModalofRescheduleReject()}  >Reject</Badge>
                    </Col>
                    
                 }
                 {selectedNotification.status==4 && <>
                {RescheduleRejectModal && <Col lg={6}>
                   Are you Sure want to Reject this Reschedule ?
                  <Button onClick={ ()=> RejectRescheduleHandler(selectedNotification)} >Cancel the Request</Button>
                  <Button  onHide={handleCloseRejectReschedule}  >Keep Pending</Button>
                
                 </Col> }
                 </>
                 }

              {selectedNotification.status==4 && <>
                {RescheduleAcceptModal && <Col lg={6}>
                Are you Sure want to Accept this Reschedule ?
          <Button onClick={ ()=> AcceptRescheduleHandler(selectedNotification)} >Accept</Button>
          <Button  onHide={()=>handleCloseReschedule()}  >Keep Pending</Button>
       
                 </Col> }
                 </>
                 }


                      
                      </Row>
                    )}

                    { ( taskStatus === "4" ) && (
                      <Row>
                              <Col md={3} style={{ padding: "10px" }}>
                    <Form.Group controlId="is_resolution_hour">
                    <Form.Label>Resolution Type</Form.Label>
                      <Form.Select  style={{height:"36.5px"}}
                        value={isSLAResolutionHour}
                        // onChange={(e) => {
                        //   console.log("iS hour:", e.target.value); // Check the value being set
                        //   setisSLAResolutionHour(e.target.value);
                        // }}
                        onChange={handleResolutionChange}
                        aria-label="Select an option"
                      >
                        <option value="">Choose...</option>
                        <option value={0}>Day</option>
                        <option value={1}>Hour</option>
                      </Form.Select>
                    </Form.Group>
                </Col>


                  {/* <Col md={3} style={{ padding: "10px" }}>
                  <Form.Group controlId="sla_resolution_time">
                    <Form.Label>Resolution Days</Form.Label>
                    <Form.Control
                      type="number"
                      name="sla_resolution_time"
                      value={editSLA}
                      onChange={(e) => seteditSLA(e.target.value)}
                      min="1"
                    />
                  </Form.Group>
                </Col>
                 */}


      {(isSLAResolutionHour === 0 || isSLAResolutionHour === "0" )  && (
        <Col md={3} style={{ padding: "10px" }}>
          <Form.Group controlId="sla_resolution_time">
            <Form.Label>Resolution Days</Form.Label>
            <Form.Control
              type="number"
              name="sla_resolution_time"
              value={editSLA}
              onChange={(e) => seteditSLA(e.target.value)}
              min="1"
            />
          </Form.Group>
        </Col>
      )}

      {/* If Hour is selected */}
      {(isSLAResolutionHour === 1  || isSLAResolutionHour === "1")  && (
        <>
          <Col md={3} style={{ padding: "10px" }}>
              <Form.Group controlId="hours">
              <Form.Label>Resolution in Hours</Form.Label>
             
              <Form.Control 
              name="hours"
              value={Thours}
              onChange={(e) =>{ 
                setHours(e.target.value)}}
              min="1"
            />
              
            </Form.Group>
          </Col>

          <Col md={3} style={{ padding: "10px" }}>
            <Form.Group controlId="minutes">
              <Form.Label>Resolution in Minutes</Form.Label>
             
              <Form.Control 
              name="minutes"
              value={Tminutes}
              onChange={(e) => setMinutes(e.target.value)}
              min="1"
            />
            </Form.Group>
          </Col>
        </>
      )}
                      </Row>
                    )}
                  </Col>

                  {/* Task Remarks */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Task Remarks
                      </Form.Label>
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}>
                        *
                      </span>
                      <Form.Control
                        type="text"
                        value={ 
                             taskRemarks 
                        }
                        className="form-control custom-input"
                        onChange={(e) => setTaskRemarks(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  {/* Response Message */}
                  <Col lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Response Message
                      </Form.Label>
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}>
                        *
                      </span>
                      <Form.Control
                        type="text"
                        value={ 
                            taskResponseMsg
                        }
                        className="form-control"
                        onChange={(e) => setTaskResponseMsg(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="text-center m-4">Task History</h4>
                {taskHistory.length > 0 ? (
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    size="sm"
                    // style={{
                    //   width: "80%", // Or any custom width like 600px or 70%
                    //   maxWidth: "1200px", // To prevent it from going too wide
                    //   margin: "0 auto", // Center the table
                    // }}
                  >
                    <thead>
                      <tr>
                        <th>Action By</th>
                        <th>Action</th>
                        <th>Task Remarks</th>
                        <th>Created At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taskHistory?.map((history, index) => (
                        <tr key={index}>
                          <td>
                            {history.user}-{history.usermail}
                            </td>
                          <td>
                            {history.status===0 && <span>Task Created</span> }  {history.status===0 && <div>& Assigned to {history.assignedToName} </div>}
                            {history.status===2 && <span> Reassigned to {history.assignedToName} </span> }
                            </td>
                          <td>

                          {history.status ===0 ? <> {history.tName}</>:
                          <>{history.remarks}</>
                          }  
                            </td>
                          <td> On {history.createdAt}</td>
                          <td>
                            <span style={{ color: "red" }}>
                              {/* {history.finalStatus.text} */}

                              {history.status===0 && "open"}
                              {history.status===1 && "Completed"}
                              {history.status===2 && "Dependency"}
                              {history.status===3 && "Rejected"}
                              {history.status===4 && "Reschedule Requested"}
                              {history.status===5 && "Reschedule Accepted"}
                              {history.status===6 && "Reschedule Cancelled"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No history available for this task.</p>
                )}

                <Row style={{ marginTop: "20px" }}>
                  <Col lg={6} md={6} sm={12}></Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-end">
                    <Button
                      className="cancel-btn me-2"
                      variant="secondary"
                      onClick={closeModal}>
                      Close
                    </Button>
                    {isStatusChanged  && (
                      <Button
                        className="update-btn"
                        variant="success"
                        onClick={handleStatusUpdate}>
                        {isSubmitting ? "Updating..." : "Update"}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal   show={showTaskModal} onHide={handleCloseTask} centered size="lg">
        <Modal.Body style={{ height:'85vh', overflowY:'auto' }}>
          <AddCustomTask FlowTask={FlowTask} handleClose={handleCloseTask} />
        </Modal.Body>
      </Modal>


      {/* <Modal   show={RescheduleAcceptModal} onHide={handleCloseReschedule} centered size="lg">
        <Modal.Body style={{ zIndex:100 }}  >

          Are you Sure want to Accept this Reschedule ?
          <Button onClick={ ()=> AcceptRescheduleHandler(selectedNotification)} >Accept</Button>
          <Button  onHide={()=>handleCloseReschedule()}  >Keep Pending</Button>
        </Modal.Body>
      </Modal>

      <Modal    show={RescheduleRejectModal} onHide={handleCloseRejectReschedule} centered size="lg">
        <Modal.Body style={{ zIndex:100 }}  >

          Are you Sure want to Reject this Reschedule ?
          <Button onClick={ ()=> RejectRescheduleHandler(selectedNotification)} >Cancel the Request</Button>
          <Button  onHide={handleCloseRejectReschedule}  >Keep Pending</Button>
        </Modal.Body>
      </Modal> */}
      {/* Swal.fire({
        title: "Error!",
        text: "There was an error updating the organization status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      }); */}
      {/* Swal.fire({
            title: "Deleted!",
            text: "Your organization has been deleted.",
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          }); */}
      {/* task_assignee_name */}
    </div>
  );
};
export default Dashboard;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SidebarComponents.css"; // Ensure you update your CSS for the new layout
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpen } from "../../redux/SideBarSlice";

export const MobileSideMenuItem = ({
  icon: Icon,isMobileScreen,
  path,
  name,
  submenu,
  isActive,
  activeItem,
  setActiveItem,
  openSubmenu,
  setOpenSubmenu,
  index, 
}) => {
  const [ismenuOpen, setIsmenuOpen] = useState(false);
  const navigate = useNavigate();

  const isOpen = useSelector(selectIsSidebarOpen);

  // const handleToggle = (e, path) => {
  //   e.preventDefault();
  //   console.log(path,e,"mypath")
  //   // Toggle submenu visibility if the menu item has a submenu
  //   if (submenu) {
  //     setIsmenuOpen((prev) => !prev);
  //   } else {
  //     setActiveItem(path);
  //     navigate(path);
  //   }
  // };

  // Handle submenu open/close
  const handleToggle = (e, path) => {
    e.preventDefault();

    // If the current menu item has a submenu
    if (submenu) {
      // If the current submenu is already open, close it
      if (openSubmenu === index) {
        setOpenSubmenu(null); // Close submenu
      } else {
        setOpenSubmenu(index); // Open the submenu corresponding to the clicked index
      }
    } else {
      // If there's no submenu, just set active and navigate
      setActiveItem(path);
      navigate(path);
    }
  };

  // Check if this menu item's submenu should be open
  const isSubmenuOpen = openSubmenu === index;  // Open if this item's index matches openSubmenu

  // Determine if the current submenu item is active
  const isSubmenuActive = submenu?.some(
    (subitem) => subitem.path === activeItem
  );

  

  return (
    <div className={`menuItem mb-3 ${isActive || isSubmenuActive ? "active" : ""}`}>
      <Link
        to={path || "#"}
        className="menuItem-link"
        onClick={(e) => handleToggle(e, path)} // Handle click to toggle submenu
      >
        <div className="menuItem-content d-flex align-items-center px-3 py-3">
          <Icon className="menuItem-icon" />
          {isOpen && <p className="menuItem-name mb-0 text-center flex-grow-1">{name}</p>}
        </div>
      </Link>

      {/* Render submenu if exists and if this item's submenu is open */}
      {submenu && isSubmenuOpen && (
        <div className="mobsubmenu-container">
          <div
            className="mobsubmenu"
            style={{
              position: "absolute",
              left: "85px",
              background: "#211c19",
              zIndex: 6,
              borderRadius: "5px",
              marginTop: "-50px",
            }}
          >
            {submenu.map((subitem, subindex) => (
              <Link
                to={subitem.path}
                key={subindex}
                className={`submenu-item ${
                  subitem.path === activeItem ? "active" : ""
                }`}
                onClick={() => {
                  setActiveItem(subitem.path); // Set active item
                  navigate(subitem.path); // Navigate to the submenu's path
                  setOpenSubmenu(null); // Close the submenu after selection
                }}
              >
                <div className="d-flex align-items-center px-2 py-3">
                  <subitem.icon className="submenuItem-icon" />
                  <p style={{ fontSize: "16px" }} className="menuItem-name mb-0">
                    {subitem.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
